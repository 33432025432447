<template>
    <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">FUEL DELIVERY</div>
            </div>
        </div>
        <div class="columns">
            <div class="column is-4 has-text-left">
                <date-range-selector
                    :dateRange="deliveryDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
        </div> 
        <hr>
       
        <div class="panel">
            <div class="panel-heading has-text-weight-bold">
                <div class="columns">
                    <div class="column has-text-centered" v-bind:key="fuel.product" v-for="fuel in fuelDeliveredByType">
                        <div>
                        <p class="is-size-7">
                            {{ fuel.product[0].toUpperCase() + fuel.product.substr(1).toLowerCase() }} 
                        </p>
                        <p class="is-size-4">
                            {{ fuel.deliverdVol | formatFuelVolume }}
                        </p>
                        </div>
                    </div>                    
                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">Total Fuel Delivery</p>
                        <p class="is-size-4">{{ totalDelivery | formatFuelVolume }}</p>
                        </div>
                    </div>
                </div>                                                                                  
            </div>
        </div>

        <b-table 
            :data='dailyGroup'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=startDate
            detailed
            detail-key="deliveryDate"
            defaultSortDirection='desc'
            >         

            <template>

                <b-table-column field="deliveryDate" label="Delivery Date" v-slot="props" sortable sorticon>
                    {{ props.row.deliveryDate.substr(0,10) | formatDate }}
                </b-table-column>

                <b-table-column numeric field="startingWaterVolume" label="Starting Water" v-slot="props" sortable sorticon>
                        <b-tooltip position="is-bottom" class="is-info" multilined>
                            <i class="has-text-info text-shadow" :class="{'fas fa-tint': props.row.startingWaterVolume > 0.00}">&nbsp;</i>
                            <template v-slot:content>
                                <div>Water Content Detected before starting delivery!</div>
                            </template>
                        </b-tooltip>
                        {{ props.row.startingWaterVolume }} 
                </b-table-column>

                <b-table-column numeric field="endingWaterVolume" label="Ending Water" v-slot="props" sortable sorticon>
                        <b-tooltip position="is-bottom" class="is-info" multilined>
                            <i class="has-text-info text-shadow" :class="{'fas fa-tint': props.row.endingWaterVolume > 0.00}">&nbsp;</i>
                            <template v-slot:content>
                                <div>Water Content Detected after delivery!</div>
                            </template>
                        </b-tooltip>
                        {{ props.row.endingWaterVolume }} 
                </b-table-column>

                <b-table-column numeric field="deliveredFuel" label="Fuel Delivered" v-slot="props" sortable sorticon>
                    {{ parseFloat(props.row.deliveredFuel) |formatFuelVolume }} 
                </b-table-column>

            </template>

            <template slot="detail" slot-scope="props">
              <b-table :data="props.row.deliveries">
                <template>
                    <b-table-column field="startDate" label="Start Date" v-slot="props" sortable sorticon>
                        {{ props.row.startDate | formatDateTime }}
                    </b-table-column>

                    <b-table-column field="endDate" label="Ending Date" v-slot="props" sortable sorticon>
                        {{ props.row.endDate  | formatDateTime}}
                    </b-table-column>

                    <b-table-column field="product" label="Product" v-slot="props" sortable sorticon>
                        {{ props.row.product }}
                    </b-table-column>

                    <b-table-column field="tankName" label="Tank" v-slot="props" sortable sorticon>
                        {{ props.row.tankName }}
                    </b-table-column>

                    <b-table-column numeric field="startingWaterVolume" label="Starting Water" v-slot="props" sortable sorticon>
                        <b-tooltip position="is-bottom" class="is-info" multilined>
                            <i class="has-text-info text-shadow" :class="{'fas fa-tint': props.row.startingWaterVolume > 0.00}">&nbsp;</i>
                            <template v-slot:content>
                                <div>Water Content Detected before starting delivery!</div>
                            </template>
                        </b-tooltip>
                        {{ props.row.startingWaterVolume }}   
                    </b-table-column>

                    <b-table-column numeric field="endingWaterVolume" label="Ending Water" v-slot="props" sortable sorticon>
                        <b-tooltip position="is-bottom" class="is-info" multilined>
                            <i class="has-text-info text-shadow" :class="{'fas fa-tint': props.row.endingWaterVolume > 0.00}">&nbsp;</i>
                            <template v-slot:content>
                                <div>Water Content Detected after delivery!</div>
                            </template>
                        </b-tooltip>
                        {{ props.row.endingWaterVolume }}                         
                    </b-table-column>
                    
                    <b-table-column numeric field="startingFuelVolume" label="Starting Fuel" v-slot="props" sortable sorticon>
                        {{ props.row.startingFuelVolume | formatFuelVolume}}
                    </b-table-column>

                    <b-table-column numeric field="endingFuelVolume" label="Ending Fuel" v-slot="props" sortable sorticon>
                        {{ props.row.endingFuelVolume | formatFuelVolume }}
                    </b-table-column> 

                    <b-table-column numeric field="delivered" label="Delivered Fuel" v-slot="props" sortable sorticon>
                        {{ (props.row.endingFuelVolume - props.row.startingFuelVolume ) | formatFuelVolume}} 
                    </b-table-column>                                       
                </template>
              </b-table>
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>
    </div>
</template>

<script>
import UserStore from "../../../store/UserStore"

import dataLoading from '../../../components/app/DataLoading'
import DateRangeSelector from "../../../components/app/DateRangeSelector"
// import moment from 'moment'
import axios from 'axios'

export default {

    components: {
        dataLoading,
        DateRangeSelector
    },

    metaInfo: {
        title: 'Fuel Delivery Report'
    },

    data() {
        return {
            deliveryDateRange: [],
            fuelDeliveredByType: [],
            delivery: [],
            dailyGroup: [],
            user: [],
            isPaginated: false,
            perPage: 31,
            isLoading: true,
            isFullPage: false
        }
    },

    methods: {

        fetchStoreDelivery() {
            
            if (this.user.activeStore) {

                var startDate = this.deliveryDateRange[0].toISOString().split('T')[0] 
                var endDate = this.deliveryDateRange[1].toISOString().split('T')[0]
                
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/tanks/deliveries' + "?start_date="+startDate+"&end_date="+endDate
                            // + '/tanks/deliveries?page=1&size=50'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.delivery = response.data.data
                        
                        this.delivery.forEach ((item) => {
                            // item.deliveryDate = new Date(item.startDate).toISOString().split('T')[0]
                            item.deliveryDate = item.startDate.split('T')[0]
                        })

                        this.groupedByDate()

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        setDateRange(dateRange) {
            this.deliveryDateRange = [dateRange[0], dateRange[1]]
        },
        
        groupedByDate() {

            this.dailyGroup = Object.values(this.delivery.reduce((dateGroup,{deliveryDate, ...props}) => {
                if(!dateGroup[deliveryDate]) {
                    dateGroup[deliveryDate] = Object.assign({}, {dateGroup: props, deliveries : [props]});
                    dateGroup[deliveryDate].deliveredFuel = 0.00
                } else {
                    dateGroup[deliveryDate].deliveries.push(props);
                }
                dateGroup[deliveryDate].deliveredFuel += props.endingFuelVolume - props.startingFuelVolume
                dateGroup[deliveryDate].deliveryDate = deliveryDate
                if (props.startingWaterVolume > 0) {
                    dateGroup[deliveryDate].startingWaterVolume = props.startingWaterVolume
                }                
                if (props.endingWaterVolume > 0) {
                    dateGroup[deliveryDate].endingWaterVolume = props.endingWaterVolume
                }
                
                return dateGroup;
            },{}));

            this.groupedDeliveryByFuel()
        },

        groupedDeliveryByFuel() {

            var result = []
            this.delivery.reduce(function(res, value) {
                if (!res[value.product]) {
                    res[value.product] = { product: value.product, deliverdVol: 0 }
                    result.push(res[value.product])
                }
                res[value.product].deliverdVol += (value.endingFuelVolume - value.startingFuelVolume)
                return res
            }, {})

            this.fuelDeliveredByType = result

        },        
       
        totalArrayColumn(array, field) {
            return array.reduce((a, b) => {
                return a + b[field];
            }, 0);
        },       

    },

    watch: {
        deliveryDateRange: function() {
            // if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchStoreDelivery()
            // }
        }

    },    

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },
        

        totalDelivery() {
            return this.dailyGroup.reduce((accum,item) => accum + parseFloat(item.deliveredFuel), 0)
        },
       
    },

    mounted() {
        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.deliveryDateRange=[firstDay, lastDay]   
        // console.log(this.dateRange)
        // this.fetchStoreDelivery()
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}

.superscript {
    vertical-align: super;
 }

 .subscript {
    vertical-align: sub;
 }

.text-shadow {
    text-shadow:  -1px 1px 0 orangered,
                1px 1px 0 orangered,
                0px -2px 0 orangered,
                -1px -1px 0 orangered
}


</style>